import styled from "styled-components";

export const FlexDiv = styled.div`
    display: flex;
`;

export const FlexColumnDiv = styled(FlexDiv)`
    flex-direction: column;
`;

export const MainContainer = styled(FlexColumnDiv)`
    height: 100vh;
    width: 100%;
`;

export const MapsContainer = styled(MainContainer)`
    min-height: 300px;
    flex: 1;
`;

export const TripsDetailsContainer = styled(FlexColumnDiv)`
    flex-direction: column;
    flex: initial;
`;


export const TripsDetailsWrapper = styled(FlexColumnDiv)`
    padding: 0px 20px 10px 20px;
    fontSize: 14px;
`;

export const DriverDetailsContainer = styled(FlexDiv)`
    justify-content: space-between;
`;

export const RiderImg = styled.img`
    border-radius: 50%;
    width: 50px;
    height: 50px;
`;

export const RoutePathIndicator = styled.div`
    background-color: #1a1a1a;
    width: 1px;
    height: 5px;
    margin: 3px 10px;
`;

export const RouteText = styled.span`
    font-size: 12px;
`;

export const FooterContainer = styled(FlexDiv)`
    padding: 5px 0;
    align-items: center;
    justify-content: space-between;
`;

export const DownloadBtn = styled.a`
    background: #F18357;
    color: white;
    padding: 10px 12px;
    border: none;
    font-size: 14px;
    border-radius: 5px;
    text-decoration: none;
    
    :hover {
        cursor: pointer;
    }
`;
