import React, { useEffect, useState } from 'react';
import './App.css';
import Maps from './component/Maps';
import { MainContainer } from './component/StyledComponents';
import socket, { SocketContext } from './services/socketService';
import TripDetails from './component/TripDetails';

function App() {
  const location = new URLSearchParams(window.location.search);
  const ride = location.get('ride'), rider = location.get('rider'), driver = location.get('driver');
  const [rideData, setRideData] = useState();
  const [tripInfo, setTripInfo] = useState();

  const rideEventCallback = async (res) => {
    if (res?.result?.ride) {
      setRideData(res?.result?.ride);
    } else if (res?.ride) {
      setRideData(res?.ride);
    }
    if (res?.info) {
      setTripInfo(res?.info);
    }
  }

  useEffect(() => {
    if (ride && rider && driver) {
      socket.emit('trip:connect', {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        body: {
          ride: ride,
          rider: rider,
          driver: driver
        }
      }, (response) => {
        console.log(response);
      });

      socket.on('connect', () => {
        console.log('Connected ids ', socket.id);
        socket.on('trip:connect::response', rideEventCallback);

        socket.on('rider:ride-accept', rideEventCallback);

        socket.on('rider:ride-approaching', rideEventCallback);

        socket.on('rider:ride-arrived', rideEventCallback);

        socket.on('rider:ride-start', rideEventCallback);

        socket.on('rider:ride-end', rideEventCallback);

        socket.on(`trip:updated`, (response) => {
          if (response?.info) {
            setTripInfo(response.info);
          }
          if (response?.result?.info) {
            setTripInfo(response.result?.info);
          }
        });

      });

      socket.on('connect_error', (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
    }
    return () => {
      socket.off('rider:ride-accept');
      socket.off('rider:ride-approaching');
      socket.off('rider:ride-arrived');
      socket.off('rider:ride-start');
      socket.off('rider:ride-end');
      socket.off('trip:updated');
    }
  }, [ride, rider, driver]);

  if (!ride || !rider || !driver || !rideData) {
    return <MainContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
      <h2 style={{ padding: '40px', color: '#979797', textAlign: 'center', fontFamily: 'serif' }}>Need more data to display the trip!</h2>
    </MainContainer>
  }

  return (
    <SocketContext.Provider value={socket}>
      {
        rideData && <MainContainer>
          <Maps rideData={rideData} tripInfo={tripInfo} />
          <TripDetails rideData={rideData} />
        </MainContainer>
      }
    </SocketContext.Provider>
  );
}

export default App;
