import React from 'react'
import { DownloadBtn, FlexDiv, FooterContainer } from './StyledComponents';
import nRydeLogo from '../assets/driver_app_logo.png';
import PlaystoreLogo from '../assets/playstore.png';
import AppleLogo from '../assets/apple.png';
import { appDownloadLinks } from '../common/constants';

const Footer = () => {
  return (
    <FooterContainer>
      <img src={nRydeLogo} alt='NRyde Logo' width='33px' height='49px' style={{ paddingTop: '14px' }}/>
      <FlexDiv style={{ flexDirection: 'column', justifyContent: 'flex-end', padding: '5px' }}>
        <span style={{ textAlign: 'end', paddingBottom: '5px', fontSize: '10px' }} >Download App On</span>
        <FlexDiv>
          <DownloadBtn style={{ marginRight: '5px' }} href={appDownloadLinks.playStore} target='_blank'>
            <img src={PlaystoreLogo} width='12px' height='12px' alt='playstore' />
            &nbsp;&nbsp;Playstore
          </DownloadBtn>
          <DownloadBtn style={{ marginLeft: '5px' }} href={appDownloadLinks.appStore} target='_blank'>
            <img src={AppleLogo} width='12px' height='12px' alt='apple logo' />
            &nbsp;&nbsp;App Store
          </DownloadBtn>
        </FlexDiv>
      </FlexDiv>
    </FooterContainer>
  )
}

export default Footer