const rad = (x) => {
    return x * Math.PI / 180;
};

export const getDistanceBetweenTwoPointsInMeter = (p1, p2) => {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
};

export const getZoomLevelByRadius = (radius) => {
    // radius will be km in this case
    const minLevel = 13, maxLevel = 18.6, minRadius = 0.2, maxRadius = 3;
    const radiusPercentage = ((radius - minRadius) * 100) / (maxRadius - minRadius);
    return ((radiusPercentage * (minLevel - maxLevel)) / 100) + maxLevel;
}

export const getZoomLevelByDistance = (driverLocation, pickUpLocation, dropOffLocation) => {
    let radiusInMeter;
    if (!!driverLocation?.lat && !!driverLocation?.lng && !!pickUpLocation?.lat && !!pickUpLocation?.lng) {
        radiusInMeter = getDistanceBetweenTwoPointsInMeter(driverLocation, pickUpLocation);

        if (!!dropOffLocation?.lat && !!dropOffLocation?.lng) {
            const distanceFromDropOff = getDistanceBetweenTwoPointsInMeter(driverLocation, dropOffLocation);
            if (radiusInMeter > distanceFromDropOff) {
                radiusInMeter = distanceFromDropOff;
            }
        }
    }
    
    if ((radiusInMeter / 1000) > 3) {
        return 13;
    } else if (radiusInMeter < 200) {
        return 18.6;
    }
    return getZoomLevelByRadius((radiusInMeter / 1000));
}
