export const RIDE_STATUS =  {
    PROCESSING: 'PROCESSING',
    PAYMENT: 'PAYMENT',
    APPROACHING: 'APPROACHING',
    ACCEPTED: 'ACCEPTED',
    ARRIVED: 'ARRIVED',
    INROUTE: 'INROUTE',
    CANCELLED: 'CANCELLED',
    COMPLETED: 'COMPLETED',
}

export const appDownloadLinks = {
    playStore: 'https://play.google.com/store/apps/details?id=com.nryde.rider',
    appStore: 'https://apps.apple.com/us/app/nryde-rider/id1624903145'
}