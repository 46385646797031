import React, { useEffect, useState } from 'react'
import { FlexColumnDiv, FlexDiv, RiderImg, RouteText, TripsDetailsContainer, TripsDetailsWrapper } from './StyledComponents';
import StartRouteIcon from '../assets/start.png';
import EndRouteIcon from '../assets/end.png';
import Footer from './Footer';
import { RIDE_STATUS } from '../common/constants';
import useWindowDimensions from '../utils/useWindowDimensions';

const TripDetails = ({ rideData }) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 800;
  const [statusText, setStatusText] = useState('is waiting to be picked up.')

  useEffect(() => {
    if (rideData?.status === RIDE_STATUS.APPROACHING || rideData?.status === RIDE_STATUS.ACCEPTED) {
      setStatusText('is waiting to be picked up.');
    } else if (rideData?.status === RIDE_STATUS.INROUTE) {
      setStatusText('is on route.');
    } else if (rideData?.status === RIDE_STATUS.COMPLETED) {
      setStatusText('has arrived.');
    }
  }, [rideData])

  return (
    <TripsDetailsContainer>
      <TripsDetailsWrapper style={{ padding: isMobile ? '' : '0 50px' }} >
        {isMobile && <h3>{`${rideData?.rider?.firstName} ${statusText}`}</h3>}
        <FlexDiv style={{ justifyContent: isMobile ? 'space-between' : 'flex-start', paddingTop: isMobile ? '' : '20px' }}>
          <>
            {!isMobile && <RiderImg src={rideData?.driver?.picture || 'man.png'} alt='Rider img' style={{ marginTop: '15px', marginRight: '15px' }} />}
            <FlexDiv style={{ flexDirection: 'column', width: '100%' }}>
              <span style={{ fontSize: '13px', fontWeight: 300 }}>DRIVER</span>
              <div style={{ display: 'flex', justifyContent: isMobile ? '' : 'space-between' }}>
                <b style={{ paddingTop: '5px' }}>{`${rideData?.driver?.firstName} ${rideData?.driver?.lastName}`}</b>
                {!isMobile && <b>{`${rideData?.rider?.firstName} ${statusText}`}</b>}
              </div>
              {isMobile && <span style={{ marginTop: '10px', fontWeight: 300, fontSize: '13px' }}>VEHICLE</span>}
              <b style={{ paddingTop: '5px' }}>{`${rideData?.vehicle?.make} ${rideData?.vehicle?.model} - ${rideData?.vehicle?.plateNumber}`}</b>
            </FlexDiv>
          </>
          {isMobile && <RiderImg src={rideData?.driver?.picture || 'man.png'} alt='Rider img' style={{ marginTop: '15px' }} />}
        </FlexDiv>
        <FlexColumnDiv style={{ padding: '15px 0' }}>
          <FlexDiv>
            <img src={StartRouteIcon} alt='start route' width='17px' height='17px' />
            <RouteText style={{ paddingLeft: '8px' }}>{rideData?.pickUp?.address}</RouteText>
          </FlexDiv>
          <FlexDiv style={{ paddingTop: '15px' }}>
            <img src={EndRouteIcon} alt='start route' width='15px' height='18px' />
            <RouteText style={{ paddingLeft: '8px'}}>{rideData?.dropOff?.address}</RouteText>
          </FlexDiv>
        </FlexColumnDiv>
        
        <div style={{ backgroundColor: '#EBEBEB', width: '100%', height: '1px', margin: '10px 0', padding: '0' }}></div>
        
        <div style={{ paddingBottom: isMobile ? '' : '20px' }}>
          <Footer />
        </div>
      </TripsDetailsWrapper>
    </TripsDetailsContainer>
  )
}

export default TripDetails;
