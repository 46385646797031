import React from 'react';
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_BASE_URL, { reconnection: false });

export const Socket = (emitName, data) => {
    socket.emit(emitName, data, (response) => {
        console.log('emit response', response);
    });

    socket.on('connection', (_socket) => {
        console.log('Connected ids ', socket.id); //
        // io.sockets.join('trip#922ea784-657c-4324-af3f-6e517fe5ef32');
    });
    socket.on('connect_error', (err) => {
        console.log(`connect_error due to ${err.message}`);
    });
    socket.on('disconnect', (_socket) => {
        console.log('Disconnected ids ', socket.id); //
        // io.sockets.join('trip#922ea784-657c-4324-af3f-6e517fe5ef32');
    });
    socket.on(`trip:updated`, (response) => {
        console.log(`trip:updated::`, response);
    });
};

export default socket;
export const SocketContext = React.createContext();
