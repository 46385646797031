import { DirectionsRenderer, DirectionsService, GoogleMap, InfoWindowF, LoadScript, MarkerF } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { RIDE_STATUS } from '../common/constants';
import { MapsContainer } from './StyledComponents';
import '../App.css';
import InfoCar from '../assets/info_car.png';
import { getZoomLevelByDistance } from '../utils/utils';

const Maps = ({ rideData, tripInfo }) => {
  const [center, setCenter] = useState({
    lat: 9.07640651025137,
    lng: 7.39859504862269
  });
  // eslint-disable-next-line
  const [map, setMap] = useState(/** @type google.maps.Map */(null));
  const [directionResponse, setDirectionResponse] = useState();
  const [zoomLevel, setZoomLevel] = useState(15);
  const [driverCoordinate, setDriverCoordinate] = useState(tripInfo?.driverLocation);

  const onLoadMarker = (marker) => {
    // console.log('marker: ', marker)
  }

  const startTripIcon = {
    url: 'start_on_route.png',
    scaledSize: {
      width: 26,
      height: 26
    }, // scaled size
    anchor: {
      x: 13, // width
      y: 13 // height
    },
    origin: {
      x: 0,
      y: 0
    },
  },
  endTripIcon = {
    url: 'end_on_route.png', /*tripDetails?.rideDatarImg || '', // url*/
    scaledSize: {
      width: 24,
      height: 40
    }, // scaled size
    anchor: {
      x: 12, // width
      y: 20 // height
    },
    origin: {
      x: 0,
      y: 0
    },
  },
  rideDatarLocationIcon = {
    url: rideData?.rideDatar?.picture || 'man.png', // url
    scaledSize: {
      width: 40,
      height: 40
    }, // scaled size
    anchor: {
      x: 20, // width
      y: 20 // height
    },
    origin: {
      x: 0,
      y: 0
    },
  },
  driverLocationIcon = {
    url: require('../assets/images/car.png'),
    scaledSize: {
      width: 40,
      height: 36
    },
    anchor: { x: 20, y: 18 },
    origin: { x: 0, y: 0 }
  }

  const directionsCallback = (response) => {
    if (response !== null) {
      if (response.status === 'OK') {
        setDirectionResponse(response);
      } else {
        console.log('response: ', response)
      }
    }
  }

  const renderMarker = (location, icon, title, label) => {
    return location?.lat && location?.lng ? (
      <>
        <MarkerF
          onLoad={onLoadMarker}
          position={location}
          icon={icon}
          label={label || ''}
          title={title || ''}
        >
          {
            title === 'DRIVER_LOCATION' &&
            <InfoWindowF
              // onLoad={onLoad}
              origin={{
                x: 0,
                y: 0
              }}
              onCloseClick={() => {
                console.log('Close Clicked!')
              }}
              position={location}
            >
              <div style={{ opacity: 1, padding: 1 }}>
                <div style={{ display: 'flex', fontSize: 16, fontColor: `#08233B`, alignItems: 'center' }}>
                  <img src={InfoCar} width='25px' height='20px' alt='trip info img' />
                  <div style={{ paddingLeft: '10px' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400 }}>{tripInfo?.etaHumanized}</div>
                    <div style={{ fontSize: '10px' }}>{tripInfo?.distanceHumanized?.toLowerCase()}</div>
                  </div>
                </div>
              </div>
            </InfoWindowF>
          }
        </MarkerF>
      </>
    ) : null;
  }

  useEffect(() => {
    if (rideData?.status === RIDE_STATUS.APPROACHING) {
      setCenter({ lat: rideData?.pickUp?.lat, lng: rideData?.pickUp?.lng });
    }

    if (rideData?.status === RIDE_STATUS.APPROACHING) {

    }

    if (rideData?.status === RIDE_STATUS.COMPLETED) {
      setCenter({ lat: rideData?.dropOff?.lat, lng: rideData?.dropOff?.lng });
      setDirectionResponse(null);
    }
  }, [rideData]);

  useEffect(() => {
    if (tripInfo?.driverLocation?.lat && tripInfo?.driverLocation?.lng) {
      const driverLocation = { lat: Number(tripInfo?.driverLocation?.lat), lng: Number(tripInfo?.driverLocation?.lng) },
        pickUpLocation = { lat: rideData?.pickUp?.lat, lng: rideData?.pickUp?.lng },
        dropOffLocation = { lat: rideData?.dropOff?.lat, lng: rideData?.dropOff?.lng };
      setDriverCoordinate(driverLocation);
      setCenter(driverLocation);
      const level = getZoomLevelByDistance(driverLocation, pickUpLocation, rideData?.status === RIDE_STATUS.INROUTE ? dropOffLocation : null);
      setZoomLevel(level);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripInfo]);

  if (!rideData) {
    return null;
  }

  return (
    <>
      <MapsContainer>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
        >
          <GoogleMap
            center={center}
            zoom={zoomLevel}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            tilt={45}
            onLoad={(map) => setMap(map)}
          >
            {
              !directionResponse && rideData?.status === RIDE_STATUS.INROUTE && (!!rideData?.pickUp && !!rideData?.dropOff) &&
              <DirectionsService
                options={{
                  destination: { lat: rideData?.dropOff?.lat, lng: rideData?.dropOff?.lng },
                  origin: { lat: rideData?.pickUp?.lat, lng: rideData?.pickUp?.lng },
                  travelMode: 'DRIVING'
                }}
                callback={directionsCallback}
              />
            }
            {
              !!directionResponse && rideData?.status === RIDE_STATUS.INROUTE &&
              <>
                <DirectionsRenderer
                  directions={directionResponse}
                  options={{
                    travelMode: 'DRIVING',
                    suppressMarkers: true,
                    polylineOptions: {
                      strokeColor: "#00A859",
                      strokeOpacity: 1.0,
                      strokeWeight: 5,
                    }
                  }}
                />
                {
                  renderMarker(directionResponse.routes[0]?.legs[0]?.start_location, startTripIcon, 'ROUTE_START_TRIP_LOCATION')
                }
                {
                  renderMarker(directionResponse.routes[0]?.legs[0]?.end_location, endTripIcon, 'ROUTE_END_TRIP_LOCATION')
                }
              </>
            }
            {
              !!driverCoordinate && (rideData?.status === RIDE_STATUS.INROUTE || rideData?.status === RIDE_STATUS.APPROACHING) &&
              renderMarker(driverCoordinate, driverLocationIcon, 'DRIVER_LOCATION')
            }

            {
              (rideData?.status === RIDE_STATUS.APPROACHING || rideData?.status === RIDE_STATUS.ARRIVED) && !!rideData?.pickUp &&
              renderMarker({ lat: rideData?.pickUp?.lat, lng: rideData?.pickUp?.lng }, rideDatarLocationIcon, 'RIDER_LOCATION')
            }
            {
              rideData?.status === RIDE_STATUS.COMPLETED && !!rideData?.dropOff &&
              renderMarker({ lat: rideData?.dropOff?.lat, lng: rideData?.dropOff?.lng }, endTripIcon, 'END_TRIP_LOCATION')
            }
          </GoogleMap>
        </LoadScript>
      </MapsContainer>
    </>
  )
}

export default Maps;